<template>
  <v-sheet class="fluid-window back-img">
    <div class="transparent content-top-padding">
      <h1 class="white--text text-center client-title-div mb-8">
        <i18n path="detail.workouts">
          <template #date>{{ $moment(filter.ondate) | moment("dddd, MMMM Do YYYY") }}</template>
        </i18n>
        
      </h1>
    </div>
    <v-row justify="center" align-content="space-between" align="center" class="mx-2">
      <v-col cols="12" sm="3" md="2">
        <v-select dark :items="clubs" v-model="filter.club" :label="$t('fields.sportclub')" clearable></v-select>
      </v-col>
      <v-col cols="12" sm="3" md="2">
        <v-select dark :items="workouts" v-model="filter.workout" :label="$t('fields.workout')" clearable></v-select>
      </v-col>
      <v-col cols="12" sm="3" md="2">
        <v-select dark :items="services" v-model="filter.service" :label="$t('fields.service')" clearable></v-select>
      </v-col>
      <v-col cols="12" sm="3" md="2">
        <v-select dark :items="coachs" v-model="filter.coach" :label="$t('fields.coach')" clearable></v-select>
      </v-col>
      <v-col cols="12" sm="3" md="2">
        <v-menu
        v-model="dtmenu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="filter.ondate"
            :label="$t('fields.ondate')"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            dark
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="filter.ondate"
          first-day-of-week="1"
          @input="dtmenu = false"
          :min="this.$moment().format('YYYY-MM-DD')"
        ></v-date-picker>
      </v-menu>
      </v-col>
    </v-row>
    <v-data-table
      dark
      :headers="headers"
      :items="items"
      item-key="idx"
      class="mx-2 main-data-table transparent"
      :footer-props="foot_props"
      :no-data-text="$t('nodata')"
    >
      <template #[`item.date`]="{item}">{{wodate(item)}}</template>
      <template #[`item.time`]="{item}">
        <TimeInterval :start="item.beginat" :duration="item.duration" />
      </template>
    </v-data-table>
  </v-sheet>
</template>
<script>
export default {
  name: "Workouts",
  components: {
    TimeInterval: () => import("../components/TimeInterval.vue"),
  },
  data() {
    return {
      dtmenu: false,
      clubs: [],
      workouts: [],
      coachs: [],  
      services: [],
      filter: {
        club: null,
        coach: null,
        workout: null,
        service: null,
        ondate: this.$moment().format("YYYY-MM-DD")
      },
      items: [],
      headers: [
        {
          text: this.$t("fields.sportclub"),
          value: "sportclub",
          filter: (value) => {
            if (!this.filter.club) return true;
            return value && value.indexOf(this.filter.club) != -1;
          },
        },
        {
          text: this.$t("fields.workout"),
          value: "display_name",
          filter: (value) => {
            if (!this.filter.workout) return true;
            return value && value.indexOf(this.filter.workout) != -1;
          },
        },
        {
          text: this.$t("fields.service"),
          value: "service",
          filter: (value) => {
            if (!this.filter.service) return true;
            return value && value.indexOf(this.filter.service) != -1;
          },
        },
        {
          text: this.$t("fields.coach"),
          value: "coach",
          filter: (value) => {
            if (!this.filter.coach) return true;
            return value && value.indexOf(this.filter.coach) != -1;
          },
        },
        {
          text: this.$t("fields.room"),
          value: "room",
          sortable: false,
        },
        {
          text: this.$t("fields.date"),
          value: "date",
          sortable: false,
          filter: (value, search, item) => {
            const dt = this.$moment(this.filter.ondate);
            if(item.fixdate){
              return item.fixdate == dt.format("YYYY-MM-DD");
            }else{
              return dt.day() == item.dayofweek;
            }
          },
        },
        {
          text: this.$t("fields.time"),
          value: "time",
          sortable: false,
        },
      ],
      foot_props: {
        itemsPerPageOptions: [14, 20, 50, 100], // -> Add this example
        showFirstLastPage: true,
        firstIcon: "mdi-arrow-collapse-left",
        lastIcon: "mdi-arrow-collapse-right",
        prevIcon: "mdi-less-than",
        nextIcon: "mdi-greater-than",
        itemsPerPageText: this.$t("rowPerPage"),
      },
    };
  },
  methods: {
    wodate(i) {
      if (i.fixdate) return i.fixdate;
      return this.$t("week").find((e) => e.value == i.dayofweek).text;
    },
  },
  mounted() {
    this.$api.workouts().then((r) => {
      try {
        this.items = [...r];
        this.items.forEach((e) => {
          if (this.clubs.indexOf(e.sportclub) == -1)
            this.clubs.push(e.sportclub);
          if (this.workouts.indexOf(e.display_name) == -1)
            this.workouts.push(e.display_name);
          if (this.coachs.indexOf(e.coach) == -1)
            this.coachs.push(e.coach);
          if (this.services.indexOf(e.service) == -1)
            this.services.push(e.service);
        });
      } catch (error) {
        this.items = [];
      }
    });
  },
};
</script>
<style scoped>
.back-img {
  background-image: url("/img/about.jpg");
  background-position: center;
  background-size: cover;
}
</style>
