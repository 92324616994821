var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"fluid-window back-img"},[_c('div',{staticClass:"transparent content-top-padding"},[_c('h1',{staticClass:"white--text text-center client-title-div mb-8"},[_c('i18n',{attrs:{"path":"detail.workouts"},scopedSlots:_vm._u([{key:"date",fn:function(){return [_vm._v(_vm._s(_vm._f("moment")(_vm.$moment(_vm.filter.ondate),"dddd, MMMM Do YYYY")))]},proxy:true}])})],1)]),_c('v-row',{staticClass:"mx-2",attrs:{"justify":"center","align-content":"space-between","align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"3","md":"2"}},[_c('v-select',{attrs:{"dark":"","items":_vm.clubs,"label":_vm.$t('fields.sportclub'),"clearable":""},model:{value:(_vm.filter.club),callback:function ($$v) {_vm.$set(_vm.filter, "club", $$v)},expression:"filter.club"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"2"}},[_c('v-select',{attrs:{"dark":"","items":_vm.workouts,"label":_vm.$t('fields.workout'),"clearable":""},model:{value:(_vm.filter.workout),callback:function ($$v) {_vm.$set(_vm.filter, "workout", $$v)},expression:"filter.workout"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"2"}},[_c('v-select',{attrs:{"dark":"","items":_vm.services,"label":_vm.$t('fields.service'),"clearable":""},model:{value:(_vm.filter.service),callback:function ($$v) {_vm.$set(_vm.filter, "service", $$v)},expression:"filter.service"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"2"}},[_c('v-select',{attrs:{"dark":"","items":_vm.coachs,"label":_vm.$t('fields.coach'),"clearable":""},model:{value:(_vm.filter.coach),callback:function ($$v) {_vm.$set(_vm.filter, "coach", $$v)},expression:"filter.coach"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('fields.ondate'),"prepend-icon":"mdi-calendar","readonly":"","dark":""},model:{value:(_vm.filter.ondate),callback:function ($$v) {_vm.$set(_vm.filter, "ondate", $$v)},expression:"filter.ondate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dtmenu),callback:function ($$v) {_vm.dtmenu=$$v},expression:"dtmenu"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1","min":this.$moment().format('YYYY-MM-DD')},on:{"input":function($event){_vm.dtmenu = false}},model:{value:(_vm.filter.ondate),callback:function ($$v) {_vm.$set(_vm.filter, "ondate", $$v)},expression:"filter.ondate"}})],1)],1)],1),_c('v-data-table',{staticClass:"mx-2 main-data-table transparent",attrs:{"dark":"","headers":_vm.headers,"items":_vm.items,"item-key":"idx","footer-props":_vm.foot_props,"no-data-text":_vm.$t('nodata')},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.wodate(item)))]}},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_c('TimeInterval',{attrs:{"start":item.beginat,"duration":item.duration}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }